import { getSymbolCurrency } from './getSymbolCurrency';
import { numberToFixed } from './numberToFixed';

export const formatMoney = ({
  value,
  currency = 'EUR',
  locale = 'ru-RU',
  showSymbol = true,
  maximumFractionDigits = 6,
  spaceCurrency = true,
}) => {
  value = numberToFixed(value, maximumFractionDigits);

  let num = value.toLocaleString(locale, {
    currency,
    maximumFractionDigits,
  });

  if (showSymbol) {
    num += spaceCurrency
      ? ` ${getSymbolCurrency(currency)}`
      : getSymbolCurrency(currency);
  }

  return num;
};
