<template>
  <d-view-card
    ref="view"
    :fetch-function="fetchPayouts"
    :filters="filters"
    :pagination="pagination"
    :filter-transformer="clearFilters"
  >
    <template
      #default="{
        loading,
        find,
        selectedData,
        removeHandler,
        data,
        filtersEntity,
      }"
    >
      <div class="col-shrink row q-px-md q-pt-md">
        <q-card class="full-width">
          <q-card-section class="row col q-col-gutter-sm">
            <d-select-filtered
              class="col-md-4 col-12"
              label="Payout method"
              clearable
              filled
              dense
              emit-value
              map-options
              option-value="id"
              option-label="name"
              :options="payoutMethodsDictionary"
              :disable="loading"
              :value="filtersEntity.payoutMethodTypeId"
              @input="setPayoutMethod"
            />
            <d-select-filtered
              v-if="!isOperatorOrAgent"
              class="col-md-4 col-12"
              label="Agent"
              clearable
              filled
              dense
              emit-value
              map-options
              option-value="id"
              option-label="username"
              :options="agentsDictionary"
              :disable="loading"
              :value="filtersEntity.agentId"
              @input="setAgent"
            />
            <q-select
              :disable="loading"
              class="col-md-4 col-12"
              filled
              label="Status"
              clearable
              dense
              emit-value
              map-options
              :value="filtersEntity.status"
              @input="setStatus"
              :options="statusDictionary"
            />

            <div class="col-12">
              <q-checkbox
                v-model="filters.emptyOrders"
                :disable="disabledEmptyOrdersFilter"
                label="Empty orders"
              />
            </div>
          </q-card-section>
          <q-card-actions align="right">
            <q-btn color="primary" label="Apply" @click="find" />
          </q-card-actions>
        </q-card>
      </div>
      <div class="col-grow">
        <div class="q-pa-md">
          <d-payout-management-table
            :selected.sync="$refs.view && $refs.view.$data.selectedData"
            :items="data"
            :grid="$q.screen.lt.md"
            :is-disable-header-selection="isDisableHeaderSelection"
            :is-disabled-selection="checkIsDisabledSelection"
            @on-change-agent="changeAgentHandler"
            @on-reject-order="rejectOrdersHandler"
          />
        </div>
      </div>
    </template>
  </d-view-card>
</template>

<script>
import { DEFAULT_FILTERS_STATE } from './config';
import { DPayoutManagementTable } from '@/features/payout-management-table';
import { DSelectFiltered } from '@/features/select-filtered';
import DViewCard from '@/layouts/view-card';
import { DDialogWithSelectTemplate } from '@/pages/dialog-with-select';
import {
  payoutManagementController,
  payoutMethodController,
  usersController,
} from '@/shared/api';
import {
  changeAgentForPayouts,
  declineOrder,
} from '@/shared/api/payoutOrder.controller';
import {
  ROLES,
  STATUSES,
  ORDER_STATUS_DICTIONARY as statusDictionary,
} from '@/shared/constants';
import { authUtils, notify, objectUtils } from '@/shared/utils';

export default {
  components: { DViewCard, DSelectFiltered, DPayoutManagementTable },
  data: () => ({
    payoutManagementController,
    filters: { ...DEFAULT_FILTERS_STATE },
    pagination: {
      lastId: 0,
      maxResults: 512,
      descending: true,
    },
    payoutMethodsDictionary: [],
    agentsDictionary: [],
    statusDictionary,
    payoutMethodController: payoutMethodController(
      authUtils.getRoleByHierarchy()
    ),
  }),

  async mounted() {
    try {
      this.$refs.view.setLoading(true);
      const { data } = await this.payoutMethodController.getPayoutMethods();
      this.payoutMethodsDictionary = data;
    } catch (e) {
      notify.error('Dictionary loading error');
    } finally {
      this.$refs.view.setLoading(false);
    }
  },

  computed: {
    isOperatorOrAgent: () =>
      authUtils.checkRoles([ROLES.ROLE_OPERATOR, ROLES.ROLE_AGENT]),
    isDisableHeaderSelection() {
      return false;
      // return this.filters.status !== STATUSES.PENDING;
    },
    firstSelectedItem() {
      if (!this.$refs.view) {
        return null;
      } else {
        return this.$refs.view.$data.selectedData?.[0];
      }
    },
    disabledEmptyOrdersFilter() {
      return this.filters.status !== STATUSES.PENDING;
    },
  },

  watch: {
    async 'filters.payoutMethodTypeId'(val) {
      if (this.isOperatorOrAgent) {
        return;
      }
      if (val) {
        await this.loadAgentsByMethodId(val);
      } else {
        this.agentsDictionary = [];
      }
    },
    disabledEmptyOrdersFilter: {
      handler(disabled) {
        if (!disabled) return;

        this.filters.emptyOrders = false;
      },
      immediate: true,
    },
  },

  methods: {
    statusErrorNotify: notify.error.bind(
      null,
      'Select payout orders with a status "New (Pending)"!',
      20000
    ),
    clearFilters: objectUtils.removeEmptyValuesRecursively,
    // checkIsDisabledSelection(scope) {
    checkIsDisabledSelection() {
      return false;
      // if (!this.firstSelectedItem) {
      //   return false;
      // } else {
      //   return (
      //     this.firstSelectedItem.payoutMethodType?.id !==
      //     scope.row.payoutMethodType?.id
      //   );
      // }
    },
    async setPayoutMethod(value) {
      this.filters.payoutMethodTypeId = value;
      this.filters.status = null;
      this.filters.agentId = null;

      if (this.isOperatorOrAgent) {
        await this.$refs.view.find();
      }
    },
    async setAgent(value) {
      this.filters.agentId = value;
      await this.$refs.view.find();
    },
    async setStatus(value) {
      this.filters.status = value;
      await this.$refs.view.find();
    },
    validateSelectedItems() {
      return !this.$refs.view.$data.selectedData.some(
        (el) => el.status !== STATUSES.PENDING
      );
    },
    async changeAgentHandler() {
      const isValid = this.validateSelectedItems();
      if (!isValid) {
        this.statusErrorNotify();
        return;
      }

      const ids = this.$refs.view.$data.selectedData.map((el) => el.id);

      const { data } = await usersController(
        authUtils.getRoleByHierarchy()
      ).agentsForPayoutMethod(this.firstSelectedItem.payoutMethodType.id);

      this.$q
        .dialog({
          component: DDialogWithSelectTemplate,
          parent: this,
          items: data,
        })
        .onOk(({ agent_id }) => {
          this.changeAgent({
            agent_id,
            ids,
          });
        });
    },

    async rejectOrdersHandler() {
      const isValid = this.validateSelectedItems();
      if (!isValid) {
        this.statusErrorNotify();
        return;
      }
      this.$q
        .dialog({
          title: 'Reject order',
          prompt: {
            model: '',
            type: 'textarea',
            isValid: (val) =>
              val.length > 0 || 'Enter the reason for rejection!',
          },
          cancel: true,
        })
        .onOk((data) => {
          this.rejectOrder({
            reason_decline: data,
            ids: this.$refs.view.$data.selectedData.map((el) => el.id),
          });
        });
    },
    async changeAgent({ ids, agent_id }) {
      try {
        this.$refs.view.setLoading(true);
        await changeAgentForPayouts({
          ids,
          agent_id,
        });
        await this.$refs.view.find();
      } catch (e) {
        notify.error('Error changing agents');
      } finally {
        this.$refs.view.setLoading(false);
      }
    },
    async rejectOrder({ ids, reason_decline }) {
      try {
        this.$refs.view.setLoading(true);
        await declineOrder({
          reason_decline,
          ids,
        });
      } catch (e) {
        notify.error('Cancellation error');
      } finally {
        this.$refs.view.setLoading(false);
      }
    },
    fetchPayouts({ filter: { emptyOrders, ...filter }, ...payload }) {
      if (emptyOrders) {
        filter.emptyOrders = emptyOrders;
      }

      return payoutManagementController.getPayouts({ ...payload, filter });
    },
    async loadAgentsByMethodId(id) {
      try {
        this.$refs.view.setLoading(true);
        const { data } = await usersController(
          authUtils.getRoleByHierarchy()
        ).agentsForPayoutMethod(id);

        this.agentsDictionary = data;
      } catch (e) {
        notify.error('Dictionary loading error');
      } finally {
        this.$refs.view.setLoading(false);
      }
    },
  },
};
</script>
