<template>
  <d-grid-card-wrapper :is-selected="item.selected">
    <q-card-section>
      <div class="grid-item__row">
        <div class="grid-item__title_inline">
          <q-checkbox dense v-model="item.selected" />
          <span class="q-mx-sm">
            {{ item.row.username }}
          </span>
        </div>
      </div>
    </q-card-section>
    <q-separator />
    <q-card-section>
      <template v-for="prop in simpleProps">
        <div :key="prop.name" class="grid-item__row">
          <div class="grid-item__title">
            {{ prop.label }}
          </div>
          <div class="grid-item__value">
            {{ prop.value }}
          </div>
        </div>
      </template>
      <div class="grid-item__row">
        <div class="grid-item__title">
          {{ item.colsMap['status'].label }}
        </div>
        <div class="grid-item__value">
          <q-chip
            dense
            square
            v-bind="getConfigAtStatusValue(item.row.status)"
            class="q-my-xs q-ml-xs q-mr-none"
          />
        </div>
      </div>
    </q-card-section>
  </d-grid-card-wrapper>
</template>

<script>
import { DGridCardWrapper } from '@/features/grid-card-wrapper';
import withStatuses from '@/shared/mixins/withStatuses';

export default {
  components: { DGridCardWrapper },
  mixins: [withStatuses],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    simpleProps: ({ item }) =>
      item.cols.filter((el) => el.name !== 'username' && el.name !== 'status'),
  },
};
</script>
