import { ORDER_STATUS_DICTIONARY as statusDictionary } from '@/shared/constants';

export default {
  data: () => ({
    statusDictionary,
  }),
  methods: {
    getConfigAtStatusValue(val) {
      const { label, color } = this.statusDictionary.find(
        (el) => el.value === val
      );
      return {
        label,
        color,
      };
    },
  },
};
