<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="text-h6"> Change agent </q-card-section>
      <q-card-section>
        <d-select-filtered
          label="Agent"
          clearable
          outlined
          emit-value
          map-options
          option-value="id"
          option-label="username"
          :options="items"
          v-model="value"
        />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat color="primary" label="Cancel" @click="onCancelClick" />
        <q-btn
          color="primary"
          label="OK"
          flat
          :disable="!value"
          @click="onOKClick"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { DSelectFiltered } from '@/features/select-filtered';

export default {
  components: {
    DSelectFiltered,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    value: null,
  }),

  methods: {
    // following method is REQUIRED
    show() {
      this.$refs.dialog.show();
    },

    // following method is REQUIRED
    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit('hide');
    },

    onOKClick() {
      this.$emit('ok', {
        agent_id: this.value,
      });
      // or with payload: this.$emit('ok', { ... })
      this.hide();
    },

    onCancelClick() {
      this.hide();
    },
  },
};
</script>
