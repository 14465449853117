var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('d-virtual-table',{attrs:{"with-pagination":"","selection":"multiple","items":_vm.items,"grid":_vm.grid,"columns":_vm.columns,"selected":_vm.selected,"is-disable-header-selection":_vm.isDisableHeaderSelection,"is-disabled-selection":_vm.isDisabledSelection,"virtual-scroll":false,"rows-per-page-options":[30, 50, 80]},on:{"update:selected":function($event){return _vm.$emit('update:selected', $event)},"on-pagination":function($event){return _vm.$emit('on-pagination', $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('q-space'),_c('div',[_c('header',{staticClass:"row items-center justify-end"},[(!_vm.isAgent && !_vm.isOperator)?_c('q-btn',{class:_vm.btnClass(true),attrs:{"disable":_vm.isDisabledActions,"color":"primary","label":"Change agent"},on:{"click":function($event){return _vm.$emit('on-change-agent')}}}):_vm._e(),_c('q-btn',{class:_vm.btnClass(),attrs:{"disable":_vm.isDisabledActions,"outline":"","color":"negative","label":"Reject orders"},on:{"click":function($event){return _vm.$emit('on-reject-order')}}})],1),_c('aside',{class:[
          'text-subtitle2 q-mb-none q-mt-sm text-right',
          {
            invisible: !_vm.totalAmountOfSelectedPayouts,
          } ],style:({
          marginBottom: '-15px',
        })},[_vm._v(" Total amount of selected payouts: "+_vm._s(_vm.totalAmountOfSelectedPayouts)+" ")])])]},proxy:true},{key:"body-cell-status",fn:function(ref){
        var props = ref.props;
return [_c('q-td',{attrs:{"auto-width":"","props":props}},[_c('q-chip',_vm._b({staticClass:"q-my-xs q-ml-xs q-mr-none",attrs:{"dense":"","square":""}},'q-chip',_vm.getConfigAtStatusValue(props.row.status),false))],1)]}},{key:"body-cell-amount",fn:function(ref){
        var props = ref.props;
return [_c('q-td',{attrs:{"props":props}},[_vm._v(" "+_vm._s(_vm.formatMoney({ value: props.row.amount, currency: props.row.currency, }))+" ")])]}},{key:"grid-item",fn:function(ref){
        var props = ref.props;
return [_c('d-grid-card',{attrs:{"item":props}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }