<template>
  <d-virtual-table
    with-pagination
    selection="multiple"
    :items="items"
    :grid="grid"
    :columns="columns"
    :selected="selected"
    :is-disable-header-selection="isDisableHeaderSelection"
    :is-disabled-selection="isDisabledSelection"
    :virtual-scroll="false"
    :rows-per-page-options="[30, 50, 80]"
    @update:selected="$emit('update:selected', $event)"
    @on-pagination="$emit('on-pagination', $event)"
  >
    <template #top>
      <q-space />

      <div>
        <header class="row items-center justify-end">
          <q-btn
            v-if="!isAgent && !isOperator"
            :disable="isDisabledActions"
            :class="btnClass(true)"
            color="primary"
            label="Change agent"
            @click="$emit('on-change-agent')"
          />
          <q-btn
            :disable="isDisabledActions"
            :class="btnClass()"
            outline
            color="negative"
            label="Reject orders"
            @click="$emit('on-reject-order')"
          />
        </header>

        <aside
          :style="{
            marginBottom: '-15px',
          }"
          :class="[
            'text-subtitle2 q-mb-none q-mt-sm text-right',
            {
              invisible: !totalAmountOfSelectedPayouts,
            },
          ]"
        >
          Total amount of selected payouts: {{ totalAmountOfSelectedPayouts }}
        </aside>
      </div>
    </template>

    <template #body-cell-status="{ props }">
      <q-td auto-width :props="props">
        <q-chip
          dense
          square
          v-bind="getConfigAtStatusValue(props.row.status)"
          class="q-my-xs q-ml-xs q-mr-none"
        />
      </q-td>
    </template>

    <template #body-cell-amount="{ props }">
      <q-td :props="props">
        {{
          formatMoney({
            value: props.row.amount,
            currency: props.row.currency,
          })
        }}
      </q-td>
    </template>
    <template v-slot:grid-item="{ props }">
      <d-grid-card :item="props" />
    </template>
  </d-virtual-table>
</template>

<script>
import { columns } from './config';
import { DGridCard } from './features';
import { ROLES } from '@/shared/constants';
import withActionTableClasses from '@/shared/mixins/withActionTableClasses';
import withStatuses from '@/shared/mixins/withStatuses';
import DVirtualTable from '@/shared/ui/virtual-table';
import { authUtils } from '@/shared/utils';
import { formatMoney } from '@/shared/utils/formatMoney';
import { numberToFixed } from '@/shared/utils/numberToFixed';

export default {
  components: { DVirtualTable, DGridCard },
  props: {
    grid: {
      type: Boolean,
      default: () => false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => undefined,
    },
    isDisableHeaderSelection: {
      type: Boolean,
      default: () => false,
    },
    isDisabledSelection: {
      type: Function,
      default: () => false,
    },
  },
  mixins: [withActionTableClasses, withStatuses],
  data: () => ({
    columns,
  }),
  computed: {
    isDisabledActions() {
      return !this.selected?.length;
    },
    isAgent: () => authUtils.getRoleByHierarchy() === ROLES.ROLE_AGENT,
    isOperator: () => authUtils.getRoleByHierarchy() === ROLES.ROLE_OPERATOR,
    totalAmountOfSelectedPayouts() {
      const selectedCurrency = this.selected?.length
        ? this.selected[0].currency
        : null;

      if (!selectedCurrency) {
        return '';
      }

      let totalAmountOfSelectedPayouts = 0;

      for (const { currency, amount } of this.selected) {
        if (currency !== selectedCurrency) {
          return '';
        }

        totalAmountOfSelectedPayouts += numberToFixed(amount, 5);
      }

      return this.formatMoney({
        value: totalAmountOfSelectedPayouts,
        currency: selectedCurrency,
      });
    },
  },
  methods: {
    formatMoney({ value, currency }) {
      return formatMoney({
        value,
        currency,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.virtual-scroll-table {
  height: 56em;
}
</style>
