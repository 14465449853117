export const columns = [
  {
    name: 'payout-method-type',
    field: (row) => row.payoutMethodType?.name,
    label: 'Payout Method',
  },
  {
    name: 'amount',
    field: 'amount',
    label: 'amount',
  },
  {
    name: 'merchant-transaction-id',
    field: 'merchantTransactionId',
    label: 'Merchant transaction id',
  },
  {
    name: 'operator-transaction-id',
    field: 'operatorTransactionId',
    label: 'Operator transaction id',
  },
  {
    name: 'status',
    field: 'status',
    label: 'Status',
  },
  {
    name: 'username',
    field: (row) => row.agent?.username,
    label: 'Agent',
  },
];
